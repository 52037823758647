const es = {
  hey: '¡hola,',
  you: 'tú!',
  nofiles: 'No se encontraron archivos para esta',
  folder: ' carpeta.',
  hashtag: ' hashtag.',
  uploadfiles: 'Subir archivos',
  or: 'o',
  back: 'Volver',
  searchAnotherHashtag: 'Buscar otro #hashtag',
  passwordEnterTitle: 'Este #hashtag está protegido.',
  passwordEnterPromt: 'Por favor ingresa la contraseña para continuar.',
  password: 'Contraseña:',
  cancel: 'Cancelar',
  submit: 'Aceptar',
  search: 'Buscar',
  hashtagDoesntExist: 'Este #hashtag no existe.',
  folderDoesntExist: 'Esta carpeta no existe.',
  createHashtag: 'Crear un hashtag',
  checkHashtag: '#hashtag',
  instructions: 'Instrucciones:',
  downloadApp: '1. Descarga la aplicación y crea un #hashtag.',
  createAndUpload: '2. Sube archivos a este #hashtag.',
  typeIn: '3. Ve todos los archivos aquí o en la aplicación',
  nameIsAlreadyTaken: 'Este nombre ya está en uso. Por favor elige otro.',
  fileType: 'Archivo',
  folderType: 'Carpeta',
  fileWasSuccessfullyRenamed: '{{type}} se ha renombrado correctamente',
  fileWasSuccessfullyDeleted: 'El archivo se eliminó correctamente',
  failedConnectToCloud:
    'No pudimos conectar con la nube. Por favor recarga la página e intenta de nuevo o contacta a nuestro equipo de soporte.',
  authModalTitle: 'Por favor elige una opción para continuar',
  signIn: 'Iniciar sesión',
  signInWithApple: 'Continuar con Apple',
  signInWithGoogle: 'Continuar con Google',
  createFolderInputPlaceholder: 'Nombre de la carpeta',
  createFolderModalTitle: 'Por favor ingresa un nombre para la carpeta',
  hashtagAlreadyExists: 'El hashtag ya ha sido creado. Por favor elige otro nombre de hashtag',
  createHashtagModalTitle: 'Piensa en un nuevo hashtag',
  createHashtagRequirement1: 'Mínimo 6 caracteres',
  createHashtagRequirement2: 'Mínimo 1 número',
  createHashtagRequirement3: 'Sin espacios ni símbolos especiales',
  createHashtagInputPlaceholder: '#hashtag',
  dropzoneText: 'Arrastra y suelta tus archivos aquí',
  dropzoneSmallText: 'Máximo {{MAX_FILES_PER_UPLOAD}} archivos',
  dropzoneBtnText: ' Explorar archivos',
  fileMoreActionsTitle: 'Más acciones',
  fileActionDelete: 'Eliminar',
  fileActionEdit: 'Editar',
  fileRenameModalTitle: 'Renombrar',
  navbarWebAppItemTitle: 'web app',
  navbariOSAppItemTitle: 'iOS app',
  navbarInfoItemTitle: 'información',
  navbarPricingItemTitle: 'precios',
  navbarHelpItemTitle: 'ayuda',
  navbarImprintItemTitle: 'pie de imprenta',
  notFoundPageTitle: '¡La página que has solicitado no se puede encontrar!',
  notFoundPageSubtitle: 'Visita nuestra página de inicio en su lugar',
  notFoundPageBtnText: 'Ir a la página de inicio',
  pageDropzoneText: 'Arrastra y suelta tus archivos aquí',
  fileUploaded: '¡Archivo subido correctamente!',
  filesUploaded: '¡Archivos subidos correctamente!',
  fileUploadModalSelectedFiles: 'Archivos seleccionados',
  fileUploadModalClearFiles: 'Limpiar archivos',
  defaultErrorMessage:
    'Algo salió mal. Por favor inténtalo de nuevo más tarde o contacta a nuestro equipo de soporte.',
  addFolder: 'Agregar carpeta',
  addFiles: 'Agregar archivos',
  profileUsername: 'Nombre de usuario',
  profileUsedStorage: 'Almacenamiento',
  profileOutOf: 'de',
  profileSignOut: 'Cerrar sesión',
  profileSupport: 'Soporte',
  outOfStorageTitle: '¡Sin espacio!',
  outOfStorageText:
    'No tienes suficiente espacio de almacenamiento disponible para subir este archivo. Puedes ampliarlo actualizando a un plan de almacenamiento diferente.',
  checkPricingPlans: 'Consulta nuestros planes',
  noFilesFound: 'No se encontraron archivos.',
  setHashtagPasswordModalTitle: 'Por favor, introduzca una contraseña para su hashtag',
  protectWithPassword: 'Proteger el hashtag con una contraseña',
  removePasswordProtection: 'Eliminar la protección por contraseña',
  hashtagPasswordUpdated: 'La contraseña se ha establecido con éxito',
  hashtagPasswordReset: 'La contraseña se ha eliminado con éxito',
  settings: 'Configuración',
  buyStorage: 'Comprar almacenamiento',
  deleteAccount: 'Eliminar cuenta',
  usernameChangeModalTitle:
    'Tu nombre de usuario estará vinculado a los archivos que estás subiendo',
  profileAccount: 'Cuenta',
  manageSubscription: 'Gestionar suscripción',
  subscriptionSuccessMessage:
    '¡Suscripción exitosa! Su almacenamiento se ha actualizado según el plan seleccionado.',
  pricingPageTitle: 'Comienza con <span>hashcloud+</span>',
  pricingPageSubtitle: 'Precio simple, cancela en cualquier momento',
  pricingFaqTitle: 'Preguntas frecuentes',
  pricingFaqQuestion1: '¿Cómo puedo comprar más espacio de almacenamiento?',
  pricingFaqAnswer1:
    'Para subir archivos a hashcloud, regístrate utilizando uno de los métodos disponibles. Puedes subir hasta 1 GB de forma gratuita. Para obtener más almacenamiento, puedes actualizar a 1000 GB por solo 3,99 € al mes.',
  pricingFaqQuestion2: '¿Cómo funciona el pago?',
  pricingFaqAnswer2:
    'Los pagos se gestionan de forma segura a través de Stripe utilizando su método de pago preferido. Su información se mantiene segura y privada; la aplicación no tiene acceso a ella.',
  pricingFaqQuestion3: '¿Cómo se calcula el espacio de almacenamiento utilizado?',
  pricingFaqAnswer3:
    'Hashcloud solo cobra por los archivos que ha subido. Ver, descargar, cambiar o eliminar no tiene costo. Ejemplo: si ha subido un archivo de 1 MB en cada uno de 10 hashtags diferentes, ha utilizado 10 MB de espacio de almacenamiento.',
  contactUsTitle: '¿Tienes alguna pregunta?',
  contactUsText: 'Envíanos un correo electrónico y te responderemos lo antes posible.',
  contactUsBtnText: 'Contáctanos',
  subscribe: 'Suscribirse',
  selected: '✅ Seleccionado',
  active: '✅ Activo',
  select: 'Seleccionar',
  per: 'por',
  month: 'mes',
  year: 'año',
  currentPlan: 'Plan actual',
  mostPopular: 'Más popular',
  subscriptionInactiveMessage:
    'Tu suscripción ya no está activa. Tu espacio de almacenamiento disponible se ha restablecido a 1 GB.',
  monthly: 'Mensual',
  yearly: 'Anual',
  standardPlanName: 'Plan Estándar',
  standardPlanDescription: '10 GB de almacenamiento incluido',
  plusPlanName: 'Plan Plus',
  plusPlanDescription: '100 GB de almacenamiento incluido',
  ultraPlanName: 'Plan Ultra',
  ultraPlanDescription: '1000 GB de almacenamiento incluido',
  close: 'Cerrar',
  company: 'Empresa',
  address: 'Dirección',
  contacts: 'Contactos',
  representedBy: 'Representado por',
  registryEntry: 'Registro',
  vatId:
    'Número de identificación del impuesto sobre el valor añadido conforme al §27a de la ley del impuesto sobre el valor añadido',
  contentResponsible: 'Responsable del contenido según § 55 Abs. 2 RStV',
  disputeResolution: 'Resolución de disputas',
  hashcloudCompanyName: 'Miosga Software UG (responsabilidad limitada)',
  hashcloudCompanyAddress: 'Karlsburg 16\n24398 Winnemark\nAlemania',
  hashcloudCompanyContacts: 'Correo electrónico: help@hash.cloud\nTeléfono: +49 (0) 151/22306315',
  hashcloudCompanyRepresentative: 'Tim Miosga',
  hashcloudCompanyRegistryEntry:
    'Registrado en el registro mercantil \nNúmero de registro mercantil: HRB 26713 KI',
  hashcloudCompanyVatId: 'DE297431012',
  hashcloudCompanyContentResponsible: 'Tim Miosga',
  disputeResolutionInfo:
    'La Comisión Europea proporciona una plataforma para la resolución de disputas en línea (ODR): <Link>https://ec.europa.eu/consumers/odr</Link><br />No estamos dispuestos ni obligados a participar en procedimientos de resolución de disputas ante una junta de arbitraje de consumidores.',
  imprintPageTitle: 'Pie de imprenta (Aviso legal)',
  termsOfUse: 'Términos de uso',
  privacyPolicy: 'Política de privacidad',
  eula: 'EULA (Acuerdo de licencia de usuario final)',
  downloadDeutsch: 'Descargar (alemán)',
  downloadEnglish: 'Descargar (inglés)',
  infoPageHeroTitle: 'Comparte archivos, simplemente con un <em>#hashtag</em>.',
  infoPageHeroFootnote:
    'Con hashcloud, puedes compartir archivos rápida y seguramente. Aquí te explicamos cómo funciona.',
  infoPageSection1Title: 'Crear un hashtag',
  infoPageSection1Content:
    'Inventa un hashtag que puedas compartir con tu familia, amigos o colegas.',
  infoPageSection2Title: 'Subir archivos',
  infoPageSection2Content:
    'Sube los archivos que quieras compartir con otros. Puedes subir fotos, videos, presentaciones, PDFs o archivos ZIP.',
  infoPageSection3Title: 'Acceso desde cualquier lugar',
  infoPageSection3Content:
    'Cualquiera que conozca tu hashtag puede acceder, subir, borrar y editar los archivos a través de la aplicación iOS o web.',
  infoPageFeature1Title: 'Compartir archivos rápidamente',
  infoPageFeature1Content:
    '¿Quieres compartir archivos? Solo crea un hashtag, sube los archivos y comparte el hashtag con otros.',
  infoPageFeature2Title: 'Tan seguro como desees',
  infoPageFeature2Content:
    'Decide la longitud y complejidad de tu hashtag para usarlo con fines privados o públicos.',
  infoPageFeature3Title: 'Alojamiento en Alemania',
  infoPageFeature3Content:
    'Todos tus archivos se almacenan en servidores en Alemania y están sujetos a las regulaciones europeas más estrictas de protección de datos.',
  imprint: 'Impresión',
  legalInformation: 'Información legal',
  copyright: 'Derechos de autor ©',
  hashcloudDirector: 'Director: Tim Miosga',
  hashcloudFooterAddress: 'Karlsburg 16 - 24398 Winnemark',
  hashcloudFooterRegistryNumber: 'HRB 26713 KI',
  hashcloudFooterVatId: 'ID IVA: DE297431012',
  eulaShort: 'EULA',
  fileActionReport: 'Reportar',
  reportHashtagModalTitle: '¿Desea reportar este hashtag?',
  reportHashtagTooltip: 'Reportar un hashtag',
  yes: 'Sí',
  no: 'No',
  fileActionsBarDownload: 'Descargar',
  fileActionsBarDownloadNumFiles: 'Descargar {{count}} archivos',
  fileActionsBarDelete: 'Eliminar',
  fileActionsBarDeleteNumFiles_one: 'Eliminar {{count}} archivo',
  fileActionsBarDeleteNumFiles_other: 'Eliminar {{count}} archivos',
  fileActionsClearSelection: 'Desmarcar selección',
  fileActionsBarNumFilesSelected_one: '{{count}} archivo seleccionado',
  fileActionsBarNumFilesSelected_other: '{{count}} archivos seleccionados',
  fileActionsBarNumOwnFilesSelected: '{{count}} subidos por ti',
  filesWereSuccessfullyDeleted: 'Los archivos se eliminaron correctamente',
  fileActionsBarFilesDeleteModalTitle_one:
    '¿Estás seguro de que deseas eliminar {{count}} archivo?',
  fileActionsBarFilesDeleteModalTitle_other:
    '¿Estás seguro de que deseas eliminar {{count}} archivos?',
  fileActionDownload: 'Descargar',
  fileActionOpen: 'Abrir',
  fileActionsBarOpen: 'Abrir',
};

export default es;
