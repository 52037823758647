const zh = {
  hey: '嘿，',
  you: '你！',
  nofiles: '未找到此文件夹的文件。',
  folder: ' 文件夹。',
  hashtag: ' 标签。',
  uploadfiles: '上传文件',
  or: '或者',
  back: '返回',
  searchAnotherHashtag: '搜索其他标签',
  passwordEnterTitle: '此标签已受保护。',
  passwordEnterPromt: '请输入密码以继续。',
  password: '密码：',
  cancel: '取消',
  submit: '确定',
  search: '搜索',
  hashtagDoesntExist: '此标签不存在。',
  folderDoesntExist: '此文件夹不存在。',
  createHashtag: '创建标签',
  checkHashtag: '#标签',
  instructions: '说明：',
  downloadApp: '1. 下载应用并创建 #标签。',
  createAndUpload: '2. 使用此 #标签上传文件。',
  typeIn: '3. 在此处或应用中查看所有文件。',
  nameIsAlreadyTaken: '此名称已被使用，请选择其他名称。',
  fileType: '文件',
  folderType: '文件夹',
  fileWasSuccessfullyRenamed: '{{type}} 已成功重命名',
  fileWasSuccessfullyDeleted: '文件已成功删除',
  failedConnectToCloud: '无法连接到云。请重新加载页面并重试，或联系我们的支持团队。',
  authModalTitle: '请选择继续的选项',
  signIn: '登录',
  signInWithApple: '使用 Apple 登录',
  signInWithGoogle: '使用 Google 登录',
  createFolderInputPlaceholder: '文件夹名称',
  createFolderModalTitle: '请输入文件夹名称',
  hashtagAlreadyExists: '该标签已创建。请选择其他标签名称。',
  createHashtagModalTitle: '想一个新的标签',
  createHashtagRequirement1: '至少6个字符',
  createHashtagRequirement2: '至少1个数字',
  createHashtagRequirement3: '不要使用空格或特殊符号',
  createHashtagInputPlaceholder: '#标签',
  dropzoneText: '将文件拖到这里',
  dropzoneSmallText: '最多 {{MAX_FILES_PER_UPLOAD}} 个文件',
  dropzoneBtnText: ' 浏览文件',
  fileMoreActionsTitle: '更多操作',
  fileActionDelete: '删除',
  fileActionEdit: '编辑',
  fileRenameModalTitle: '重命名',
  navbarWebAppItemTitle: 'Web 应用',
  navbariOSAppItemTitle: 'iOS 应用',
  navbarInfoItemTitle: '信息',
  navbarPricingItemTitle: '定价',
  navbarHelpItemTitle: '帮助',
  navbarImprintItemTitle: '版权声明',
  notFoundPageTitle: '找不到请求的页面！',
  notFoundPageSubtitle: '请访问我们的主页',
  notFoundPageBtnText: '转到主页',
  pageDropzoneText: '将文件拖到这里',
  fileUploaded: '文件上传成功！',
  filesUploaded: '文件上传成功！',
  fileUploadModalSelectedFiles: '已选择的文件',
  fileUploadModalClearFiles: '清除文件',
  defaultErrorMessage: '出了些问题。请稍后再试，或联系我们的支持团队。',
  addFolder: '添加文件夹',
  addFiles: '添加文件',
  profileUsername: '用户名',
  profileUsedStorage: '已用存储空间',
  profileOutOf: '的',
  profileSignOut: '退出登录',
  profileSupport: '支持',
  outOfStorageTitle: '存储空间不足！',
  outOfStorageText: '您没有足够的存储空间来上传此文件。您可以升级到其他存储计划来扩展存储空间。',
  checkPricingPlans: '查看我们的计划',
  noFilesFound: '未找到文件。',
  setHashtagPasswordModalTitle: '请输入您的标签密码',
  protectWithPassword: '使用密码保护标签',
  removePasswordProtection: '取消密码保护',
  hashtagPasswordUpdated: '密码设置成功',
  hashtagPasswordReset: '密码删除成功',
  settings: '设置',
  buyStorage: '购买存储',
  deleteAccount: '删除账户',
  usernameChangeModalTitle: '您的用户名将与您上传的文件相关联',
  profileAccount: '账户',
  manageSubscription: '管理订阅',
  subscriptionSuccessMessage: '订阅成功！ 您的存储已根据所选计划更新。',
  pricingPageTitle: '开始使用 <span>hashcloud+</span>',
  pricingPageSubtitle: '简单定价，随时取消',
  pricingFaqTitle: '常见问题',
  pricingFaqQuestion1: '如何购买更多的存储空间？',
  pricingFaqAnswer1:
    '要上传文件到hashcloud，请使用其中一种可用方法注册。您可以免费上传最多1 GB的文件。如需更多存储空间，您可以升级到1000 GB，费用仅为每月€3.99。',
  pricingFaqQuestion2: '支付方式如何工作？',
  pricingFaqAnswer2:
    '通过Stripe安全处理支付，使用您选择的支付方式。您的信息将保持安全和私密；应用程序不会访问它。',
  pricingFaqQuestion3: '如何计算已使用的存储空间？',
  pricingFaqAnswer3:
    'Hashcloud仅针对您上传的文件收费。查看、下载、更改或删除不收费。例如：如果您在10个不同的标签中每个上传了1 MB的文件，则使用了10 MB的存储空间。',
  contactUsTitle: '有任何问题吗？',
  contactUsText: '发送电子邮件给我们，我们将尽快回复您。',
  contactUsBtnText: '联系我们',
  subscribe: '订阅',
  selected: '✅ 已选择',
  active: '✅ 活跃',
  select: '选择',
  per: '每',
  month: '月',
  year: '年',
  currentPlan: '当前计划',
  mostPopular: '最受欢迎',
  subscriptionInactiveMessage: '您的订阅已不再有效。可用存储空间已重置为 1 GB。',
  monthly: '每月',
  yearly: '每年',
  standardPlanName: '标准计划',
  standardPlanDescription: '包含10 GB存储空间',
  plusPlanName: '高级计划',
  plusPlanDescription: '包含100 GB存储空间',
  ultraPlanName: '超大计划',
  ultraPlanDescription: '包含1000 GB存储空间',
  close: '关闭',
  company: '公司',
  address: '地址',
  contacts: '联系方式',
  representedBy: '代表人',
  registryEntry: '注册条目',
  vatId: '根据增值税法第§27a条的增值税识别号',
  contentResponsible: '内容负责人（根据§ 55 Abs. 2 RStV）',
  disputeResolution: '争议解决',
  hashcloudCompanyName: 'Miosga Software UG (有限责任)',
  hashcloudCompanyAddress: 'Karlsburg 16\n24398 Winnemark\n德国',
  hashcloudCompanyContacts: '电子邮件: help@hash.cloud\n电话: +49 (0) 151/22306315',
  hashcloudCompanyRepresentative: '蒂姆·米奥斯加',
  hashcloudCompanyRegistryEntry: '注册于商业登记册 \n商业登记号: HRB 26713 KI',
  hashcloudCompanyVatId: 'DE297431012',
  hashcloudCompanyContentResponsible: '蒂姆·米奥斯加',
  disputeResolutionInfo:
    '欧盟委员会提供在线争议解决平台（ODR）：<Link>https://ec.europa.eu/consumers/odr</Link><br />我们不愿意也没有义务参加消费者仲裁委员会的争议解决程序。',
  imprintPageTitle: '版权说明（法律声明）',
  termsOfUse: '使用条款',
  privacyPolicy: '隐私政策',
  eula: '最终用户许可协议（EULA）',
  downloadDeutsch: '下载（德语）',
  downloadEnglish: '下载（英语）',
  infoPageHeroTitle: '通过<em>#标签</em>简单地共享文件。',
  infoPageHeroFootnote: '使用hashcloud，您可以快速安全地共享文件。以下是操作方法。',
  infoPageSection1Title: '创建标签',
  infoPageSection1Content: '想出一个标签，可以与您的家人、朋友或同事分享。',
  infoPageSection2Title: '上传文件',
  infoPageSection2Content: '上传您想与他人分享的文件。可以上传照片、视频、演示文稿、PDF或ZIP文件。',
  infoPageSection3Title: '随时随地访问',
  infoPageSection3Content: '任何知道您标签的人都可以通过iOS或Web应用访问、上传、删除和编辑文件。',
  infoPageFeature1Title: '快速共享文件',
  infoPageFeature1Content: '想要分享文件？只需创建一个标签，上传文件，然后将标签分享给其他人。',
  infoPageFeature2Title: '随您所愿的安全性',
  infoPageFeature2Content: '决定标签的长度和复杂性，以便用于私人或公共目的。',
  infoPageFeature3Title: '德国托管',
  infoPageFeature3Content: '您所有的文件都存储在德国的服务器上，并遵守最高的欧洲数据保护规定。',
  imprint: '印记',
  legalInformation: '法律信息',
  copyright: '版权 ©',
  hashcloudDirector: '导演：蒂姆·米奥斯加',
  hashcloudFooterAddress: '卡尔斯堡16号 - 24398 温内马克',
  hashcloudFooterRegistryNumber: 'HRB 26713 KI',
  hashcloudFooterVatId: '增值税ID：DE297431012',
  eulaShort: '最终用户许可协议',
  fileActionReport: '举报',
  reportHashtagModalTitle: '您要举报此标签吗？',
  reportHashtagTooltip: '举报标签',
  yes: '是',
  no: '否',
  fileActionsBarDownload: '下载',
  fileActionsBarDownloadNumFiles: '下载 {{count}} 个文件',
  fileActionsBarDelete: '删除',
  fileActionsBarDeleteNumFiles_one: '删除 {{count}} 个文件',
  fileActionsBarDeleteNumFiles_other: '删除 {{count}} 个文件',
  fileActionsClearSelection: '清除选择',
  fileActionsBarNumFilesSelected_one: '已选择 {{count}} 个文件',
  fileActionsBarNumFilesSelected_other: '已选择 {{count}} 个文件',
  fileActionsBarNumOwnFilesSelected: '您上传的 {{count}} 个文件',
  filesWereSuccessfullyDeleted: '文件已成功删除',
  fileActionsBarFilesDeleteModalTitle_one: '确定要删除 {{count}} 个文件吗？',
  fileActionsBarFilesDeleteModalTitle_other: '确定要删除 {{count}} 个文件吗？',
  fileActionDownload: '下载',
  fileActionOpen: '打开',
  fileActionsBarOpen: '打开',
};

export default zh;
