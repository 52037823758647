const en = {
  hey: 'hey,',
  you: 'you!',
  nofiles: 'No files found for this',
  folder: ' folder.',
  hashtag: ' hashtag.',
  uploadfiles: 'Upload files',
  or: 'or',
  back: 'Back',
  searchAnotherHashtag: 'Search another #hashtag',
  passwordEnterTitle: 'This #hashtag is protected.',
  passwordEnterPromt: 'Please enter the password to continue.',
  password: 'Password:',
  cancel: 'Cancel',
  submit: 'OK',
  search: 'Search',
  hashtagDoesntExist: 'This #hashtag does not exist.',
  folderDoesntExist: 'This folder does not exist.',
  createHashtag: 'Create a hashtag',
  checkHashtag: '#hashtag',
  instructions: 'Instructions:',
  downloadApp: '1. Download the app and create a #hashtag.',
  createAndUpload: '2. Upload files to this #hashtag.',
  typeIn: '3. View all files here or in the app',
  nameIsAlreadyTaken: 'This name has already been taken. Please choose another one',
  fileType: 'File',
  folderType: 'Folder',
  fileWasSuccessfullyRenamed: '{{type}} was successfully renamed',
  fileWasSuccessfullyDeleted: 'File was successfully deleted',
  failedConnectToCloud:
    "We couldn't connect to the cloud. Please reload the page and try again or reach out to our support team.",
  authModalTitle: 'Please choose an option to continue',
  signIn: 'Sign in',
  signInWithApple: 'Continue with Apple',
  signInWithGoogle: 'Continue with Google',
  createFolderInputPlaceholder: 'Folder name',
  createFolderModalTitle: 'Please enter a folder name',
  hashtagAlreadyExists:
    'The given hashtag has already been created. Please choose another hashtag name',
  createHashtagModalTitle: 'Think of new hashtag',
  createHashtagRequirement1: 'Minimum 6 characters',
  createHashtagRequirement2: 'Minimum 1 number',
  createHashtagRequirement3: 'No whitespaces or special symbols',
  createHashtagInputPlaceholder: '#hashtag',
  dropzoneText: 'Drag and Drop your files here',
  dropzoneSmallText: 'Maximum {{MAX_FILES_PER_UPLOAD}} files',
  dropzoneBtnText: ' Browse Files',
  fileMoreActionsTitle: 'More actions',
  fileActionDelete: 'Delete',
  fileActionEdit: 'Edit',
  fileRenameModalTitle: 'Rename',
  navbarWebAppItemTitle: 'web app',
  navbariOSAppItemTitle: 'iOS app',
  navbarInfoItemTitle: 'info',
  navbarPricingItemTitle: 'pricing',
  navbarHelpItemTitle: 'help',
  navbarImprintItemTitle: 'imprint',
  notFoundPageTitle: "The page you've requested can't be found!",
  notFoundPageSubtitle: 'Check out our homepage instead',
  notFoundPageBtnText: 'Go to the homepage',
  pageDropzoneText: 'Drag and Drop your files here',
  fileUploaded: 'File uploaded succesfully!',
  filesUploaded: 'Files uploaded succesfully!',
  fileUploadModalSelectedFiles: 'Selected files',
  fileUploadModalClearFiles: 'Clear files',
  defaultErrorMessage: 'Something went wrong. Please try again later or contact our support team.',
  addFolder: 'Add Folder',
  addFiles: 'Add Files',
  profileUsername: 'Username',
  profileUsedStorage: 'Storage',
  profileOutOf: 'out of',
  profileSignOut: 'Logout',
  profileSupport: 'Support',
  outOfStorageTitle: 'Out out storage!',
  outOfStorageText:
    "You don't have enough storage space available to upload this file. You can extend it by upgrading to a different storage plan.",
  checkPricingPlans: 'Check our plans',
  noFilesFound: 'No files found.',
  setHashtagPasswordModalTitle: 'Please enter a password for your hashtag',
  protectWithPassword: 'Protect hashtag with a password',
  removePasswordProtection: 'Remove password protection',
  hashtagPasswordUpdated: 'The password was successfully set',
  hashtagPasswordReset: 'The password was successfully removed',
  settings: 'Settings',
  buyStorage: 'Buy storage',
  deleteAccount: 'Delete account',
  usernameChangeModalTitle: 'Your username will be linked to the files you are uploading',
  profileAccount: 'Account',
  manageSubscription: 'Manage subscription',
  subscriptionSuccessMessage:
    'Subscription successful! Your storage has been updated according to your selected plan.',
  pricingPageTitle: 'Get started with <span>hashcloud+</span>',
  pricingPageSubtitle: 'Simple Pricing, Cancel Anytime',
  pricingFaqTitle: 'Frequently asked questions',
  pricingFaqQuestion1: 'How can I purchase more storage space?',
  pricingFaqAnswer1:
    'To upload files to hashcloud, sign up using one of the available methods. You can upload up to 1 GB for free. For more storage, you can upgrade to 1000 GB for just €3.99 per month.',
  pricingFaqQuestion2: 'How does payment work?',
  pricingFaqAnswer2:
    'Payments are securely handled through Stripe using your preferred payment method. Your information is kept safe and private; the app does not access it.',
  pricingFaqQuestion3: 'How the used storage space is calculated',
  pricingFaqAnswer3:
    'Hashcloud only charges for the files that you have uploaded. Viewing, downloading, changing or deleting is not charged. Example: If you have uploaded a file with 1 MB in each of 10 different hashtags, you have used 10 MB of storage space.',
  contactUsTitle: 'Do you have any questions?',
  contactUsText: 'Send us an e-mail and we will get back to you as soon as possible.',
  contactUsBtnText: 'Contact us',
  subscribe: 'Subscribe',
  selected: '✅ Selected',
  active: '✅ Active',
  select: 'Select',
  per: 'per',
  month: 'month',
  year: 'year',
  currentPlan: 'Current plan',
  mostPopular: 'Most popular',
  subscriptionInactiveMessage:
    'Your subscription is no longer active. Your available storage has been reset to 1 GB.',
  monthly: 'Monthly',
  yearly: 'Yearly',
  standardPlanName: 'Standard plan',
  standardPlanDescription: '10 GB of storage included',
  plusPlanName: 'Plus plan',
  plusPlanDescription: '100 GB of storage included',
  ultraPlanName: 'Ultra plan',
  ultraPlanDescription: '1000 GB of storage included',
  close: 'Close',
  company: 'Company',
  address: 'Address',
  contacts: 'Contacts',
  representedBy: 'Represented by',
  registryEntry: 'Register entry',
  vatId: 'Value added tax identification number in accordance with §27a of the Value Added Tax Act',
  contentResponsible: 'Responsible for content according to § 55 Abs. 2 RStV',
  disputeResolution: 'Dispute resolution',
  hashcloudCompanyName: 'Miosga Software UG (limited liability)',
  hashcloudCompanyAddress: 'Karlsburg 16\n24398 Winnemark\nGermany',
  hashcloudCompanyContacts: 'E-mail: help@hash.cloud\nPhone: +49 (0) 151/22306315',
  hashcloudCompanyRepresentative: 'Tim Miosga',
  hashcloudCompanyRegistryEntry:
    'Registered in the commercial register \nCommercial register number: HRB 26713 KI',
  hashcloudCompanyVatId: 'DE297431012',
  hashcloudCompanyContentResponsible: 'Tim Miosga',
  disputeResolutionInfo:
    'The European Commission provides a platform for online dispute resolution (ODR): <Link>https://ec.europa.eu/consumers/odr</Link><br />We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.',
  imprintPageTitle: 'Imprint (Legal Notice)',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  eula: 'EULA (End-User License Agreement)',
  downloadDeutsch: 'Download (German)',
  downloadEnglish: 'Download (English)',
  infoPageHeroTitle: 'Share files, simply with a <em>#hashtag</em>.',
  infoPageHeroFootnote:
    'With hashcloud, you can share files quickly and securely. Here’s how it works.',
  infoPageSection1Title: 'Create a hashtag',
  infoPageSection1Content:
    'Come up with a hashtag that you can share with your family, friends, or colleagues.',
  infoPageSection2Title: 'Upload files',
  infoPageSection2Content:
    'Upload files you want to share with others. You can upload photos, videos, presentations, PDFs, or ZIP files.',
  infoPageSection3Title: 'Access from anywhere',
  infoPageSection3Content:
    'Anyone who knows your hashtag can access, upload, delete, and edit the files via the iOS or web app.',
  infoPageFeature1Title: 'Fast file sharing',
  infoPageFeature1Content:
    'Want to share files? Just create a hashtag, upload the files, and share the hashtag with others.',
  infoPageFeature2Title: 'As secure as you want',
  infoPageFeature2Content:
    'Decide how long and complex your hashtag should be to use it for private or public purposes.',
  infoPageFeature3Title: 'Hosting in Germany',
  infoPageFeature3Content:
    'All your files are stored on servers in Germany and are subject to the highest European data protection regulations.',
  imprint: 'Imprint',
  legalInfromation: 'Legal information',
  copyright: 'Copyright ©',
  hashcloudDirector: 'Director: Tim Miosga',
  hashcloudFooterAddress: 'Karlsburg 16 - 24398 Winnemark',
  hashcloudFooterRegistryNumber: 'HRB 26713 KI',
  hashcloudFooterVatId: 'USt-IdNr: DE297431012',
  eulaShort: 'EULA',
  fileActionReport: 'Report',
  reportHashtagModalTitle: 'Do you want to report this hashtag?',
  reportHashtagTooltip: 'Report a hashtag',
  yes: 'Yes',
  no: 'No',
  fileActionsBarDownload: 'Download',
  fileActionsBarDownloadNumFiles: 'Download {{count}} files',
  fileActionsBarDelete: 'Delete',
  fileActionsBarDeleteNumFiles_one: 'Delete {{count}} file',
  fileActionsBarDeleteNumFiles_other: 'Delete {{count}} files',
  fileActionsClearSelection: 'Clear selection',
  fileActionsBarNumFilesSelected_one: '{{count}} file selected',
  fileActionsBarNumFilesSelected_other: '{{count}} files selected',
  fileActionsBarNumOwnFilesSelected: '{{count}} uploaded by you',
  filesWereSuccessfullyDeleted: 'Files were successfully deleted',
  fileActionsBarFilesDeleteModalTitle_one: 'Are you sure you want to delete {{count}} file?',
  fileActionsBarFilesDeleteModalTitle_other: 'Are you sure you want to delete {{count}} files?',
  fileActionDownload: 'Download',
  fileActionOpen: 'Open',
  fileActionsBarOpen: 'Open',
};

export default en;
