import { useEffect } from 'react';
import { useMount, useSetState } from 'react-use';
import { Trans, useTranslation } from 'react-i18next';

import BillingPeriodSwitcher from 'components/BillingPeriodSwitcher';
import ContactUs from 'components/ContactUs';
import PagePreloader from 'components/PagePreloader';
import PricingFaq from 'components/PricingFaq';
import PricingPlan from 'components/PricingPlan';

import useAuth from 'hooks/useAuth';
import useCustomerPortal from 'hooks/useCustomerPortal';
import useGlobalState from 'hooks/useGlobalState';
import useSubscription from 'hooks/useSubscription';

import { IProductWithPrices, TBillingPeriod, billingPeriodOptions } from 'types/interfaces';

import { getCheckoutUrl, getProductsAndPrices } from 'utils/stripe';
import { showDefaultErrorNotification } from 'utils/notifications';
import consoleLogDev from 'utils/consoleLogDev';

import styles from './Pricing.module.scss';

interface IProps {}

interface ILocalState {
  products: IProductWithPrices[];
  areProductsLoading: boolean;
  isStripeSessionLoading: boolean;
  billingPeriod: TBillingPeriod;
}

const Pricing = (_props: IProps) => {
  const { t } = useTranslation();
  const { refreshSubscriptionData } = useSubscription();
  const { showAuthModal } = useAuth();
  const { isPortalUrlLoading, navigatePortalUrl } = useCustomerPortal();

  const [globalState, _setGlobalState] = useGlobalState();
  const {
    isAuthorized,
    subscription,
    subscriptionPrice,
    isSubscriptionLoading,
    hasActiveOrRenewableSubscription,
    profile,
  } = globalState;

  const [localState, setLocalState] = useSetState<ILocalState>({
    products: [],
    areProductsLoading: false,
    isStripeSessionLoading: false,
    billingPeriod: billingPeriodOptions[0],
  });
  const { products, areProductsLoading, isStripeSessionLoading, billingPeriod } = localState;

  useEffect(() => {
    if (!subscriptionPrice?.interval) return;

    setLocalState({
      billingPeriod: subscriptionPrice?.interval,
    });
  }, [subscriptionPrice?.interval, setLocalState]);

  useMount(async () => {
    refreshSubscriptionData(profile);

    setLocalState({ areProductsLoading: true });

    const products = await getProductsAndPrices();

    const getProductMonthlyPrice = (product: IProductWithPrices) => {
      const monthlyPrice = Object.values(product.prices)?.find(
        price => price.active && price.interval === 'month'
      );

      return monthlyPrice?.unit_amount || 0;
    };

    const filteredProducts = products.sort((productA, productB) => {
      const priceA = getProductMonthlyPrice(productA);
      const priceB = getProductMonthlyPrice(productB);

      return priceA - priceB;
    });

    setLocalState({ products: filteredProducts, areProductsLoading: false });
  });

  const handlePlanClick = (priceId: string) => async () => {
    if (!isAuthorized) {
      showAuthModal();

      return;
    }

    try {
      setLocalState({ isStripeSessionLoading: true });

      if (hasActiveOrRenewableSubscription) {
        const flow_data = {
          type: 'subscription_update_confirm',
          subscription_update_confirm: {
            subscription: subscription?.id,
            items: [
              {
                id: subscription?.items[0]?.id,
                quantity: 1,
                price: priceId,
              },
            ],
          },
        };

        navigatePortalUrl({ flow_data });

        return;
      } else {
        const checkoutUrl = await getCheckoutUrl(priceId);
        window.location.href = checkoutUrl;

        setLocalState({ isStripeSessionLoading: false });
      }
    } catch (error) {
      consoleLogDev(error);

      showDefaultErrorNotification();
      setLocalState({ isStripeSessionLoading: false });
    }
  };

  const handleBillingPeriodChange = (billingPeriod: TBillingPeriod) => {
    setLocalState({
      billingPeriod,
    });
  };

  const hasYearlyPriceOptions = products.some(product =>
    Object.values(product.prices).some(price => price.interval === 'year')
  );

  return (
    <>
      {(isStripeSessionLoading || isPortalUrlLoading) && (
        <div className={styles.spinnerOverlay}>
          <PagePreloader />
        </div>
      )}

      <div className={styles.container}>
        <div className={styles.titles}>
          <h1 className={styles.title}>
            <Trans i18nKey="pricingPageTitle" />
          </h1>
          <h3 className={styles.subtitle}>{t('pricingPageSubtitle')}</h3>
        </div>

        {areProductsLoading || isSubscriptionLoading ? (
          <>
            <div className={styles.plansPreloaderContainer}>
              <PagePreloader />
            </div>
          </>
        ) : (
          <>
            {hasYearlyPriceOptions && (
              <BillingPeriodSwitcher
                value={billingPeriod}
                className={styles.billingPeriodSwitcher}
                onChange={handleBillingPeriodChange}
              />
            )}

            <div className={styles.plans}>
              {products.map((product, index) => {
                return (
                  <PricingPlan
                    className={styles.plan}
                    key={`${product.productId}-${billingPeriod}`}
                    product={product}
                    products={products}
                    index={index}
                    billingPeriod={billingPeriod}
                    onClick={handlePlanClick}
                  />
                );
              })}
            </div>
          </>
        )}

        <div className={styles.narrowContainer}>
          <PricingFaq />
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export default Pricing;
